/* eslint-disable react-hooks/exhaustive-deps */
import Checkbox from "@mui/material/Checkbox";
import { isEmptyArray } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import filters from "../../../assets/images/filters.svg";
import filterClose from "../../../assets/images/filterClose.png";
import search from "../../../assets/images/search.svg";
import DeleteConfirmation from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import Layout from "../../../components/Layout";
import AddNewFirm from "../../../components/Popup/AddNewFirm/AddNewFirm";
import ImportFromCSV from "../../../components/Popup/ImportFirmLawyers/ImportFromCsv";
import SuperAdminFilter from "../../../components/Popup/SuperAdminFilter";
import ReactPagination from "../../../components/ReactPagination/ReactPagination";
import RegisteredFirmsCard from "../../../components/RegisteredFirmsCard";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import {
	useDeleteFirmMutation,
	useGetFirmSearchMutation,
	useGetFirmsListQuery,
	useGetAllFirmsQuery,
} from "../../../redux/services/Firm/FirmLawyer";
import { useGetAnalyticsDataQuery } from "../../../redux/services/Dashboard";
import AnalyticsTile from "../../../components/AnalyticsTile";
import icon3 from "../../../assets/images/icon3.svg";
import { useLocation } from 'react-router-dom';
const RegisteredFirms = () => {
	const searchInput = document.getElementById(
		"searchInput"
	) as HTMLInputElement;
	const [addFirm, setAddFirm] = useState(false);
	const [filter, setFilter] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [importFirms, setImportFirms] = useState(false);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [deleteFirm, setDeleteFirm] = useState(false);
	const [updateFirm, setUpdateFirm] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [bulkDeleteFirms, setBulkDeleteFirms] = useState(false);
	const [selectAll, setSelectAll] = useState(false);
	const [searchText, setSearchText] = useState<any | null>("");
	const [firms, setFirms] = useState<any | null>(null);
	const [filteredData, setFilteredData] = useState<any | null>(null);
	const timeout: { current: NodeJS.Timeout | null } = useRef(null);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const registeredParam = params.get('registered');
	const thisMonthParam = params.get('this_month');
	const queryParams = {
		...(registeredParam && { registered: registeredParam }),
		...(thisMonthParam && { this_month: thisMonthParam })
	  };

	const { data: allFirms } = useGetAllFirmsQuery(null);
	const [deleteMultiFirm, { isSuccess, isError }] = useDeleteFirmMutation();
	const { data, isLoading, refetch } = useGetFirmsListQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
		...queryParams
	});

	const {
		data: analyticsData,
		isLoading: isAnalyticsLoading,
		isSuccess: isAnalyticsSuccess,
	} = useGetAnalyticsDataQuery({
		refetchOnMountOrArgChange: true,
	});

	const [
		searching,
		{
			data: searchData,
			isLoading: searchLoading,
			isSuccess: isSearchSuccess,
		},
	] = useGetFirmSearchMutation();

	const handleSetAddFirm = () => {
		setAddFirm(!addFirm);
	};

	const handleUpdate = () => {
		setUpdateFirm(!updateFirm);
	};
	const handleDelete = () => {
		setDeleteFirm(!deleteFirm);
	};

	useEffect(() => {
		const rowsCheck = data ? data.total_records / rowsPerPage : 1;
		setSearchText("");
		setFilteredData(null);
		if (~~rowsCheck < pageNumber) {
			setPageNumber(1);
		}
		refetch();
	}, [addFirm, deleteFirm, pageNumber, rowsPerPage, isSuccess]);

	const handleImportFirms = () => {
		setImportFirms(!importFirms);
	};

	const handleSearch = (search: string) => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		setSearchText(search);
		timeout.current = setTimeout(() => {
			if (search.length > 0) {
				searching({ search: search });
			} else {
				setFilteredData(null);
				setFirms(data?.firms);
			}
		}, 1000);
	};

	const handleBulkDeleteFirms = () => {
		setBulkDeleteFirms(!bulkDeleteFirms);
	};

	useEffect(() => {
		if (searchData?.firms) {
			setFilteredData(searchData?.firms);
		} else {
			setFirms(data?.firms);
		}
	}, [searchData, data]);

	useEffect(() => {
		if (selectedItems?.length === firms?.length) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [selectedItems]);

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(firms?.map((item: { id: any }) => item.id));
		}
		setSelectAll(!selectAll);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleFilter = () => {
		setFilter(!filter);
		setSearchText("");
	};

	useEffect(() => {
		if (deleteFirm) {
			deleteMultiFirm(selectedItems);
			setDeleteFirm(false);
		}
		if (isSuccess) {
			toast.success("Firms Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
			setDeletePopUp(false);
		}
		if (isError) {
			toast.error(
				"Could not delete firms at the moment, Please try again"
			);
		}
	}, [deleteFirm, isSuccess, isError]);

	const handleClearFilter = () => {
		setSearchText("");
		setFilteredData(null);
	};

	searchInput?.addEventListener("input", function () {
		if (searchInput?.value === "") {
			setFilteredData(null);
		}
	});

	return (
		<Layout>
			<section id="manage_registered_firms" className="sec_pad_btm ">
				<div
					style={{
						maxWidth: "180rem",
						padding: "0rem 2rem",
						margin: "0 auto",
					}}
				>
					<div id="dashboard">
						<AnalyticsTile
							title="Firms"
							icon={icon3}
							path=""
							count={analyticsData?.firms?.total_count}
							currentMonth={
								analyticsData?.firms?.entries_within_period
							}
							percentage={
								analyticsData?.firms?.percentage_difference
							}
							mobileRegistered={analyticsData?.firms?.registered_on_mobile_count}
							mobileUnregistered={analyticsData?.firms?.unregistered_on_mobile_count}
						/>
					</div>
					<div className="top_blk mb-5">
						<TopBlock
							title="Registered Firms"
							url="dashboard"
							button={true}
							setAddFirm={setAddFirm}
							handleImport={handleImportFirms}
							buttonText={"Add New Firm"}
							importButtonText="Import Firms"
							deleteButtonText="Delete Via CSV"
							handleDelete={handleBulkDeleteFirms}
							firms={allFirms}
						/>
					</div>
					<div
						className="search_container"
						style={{
							justifyContent: "space-between",
							backgroundColor: "#63a744",
							alignItems: "center",
							padding: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "4rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
									}}
								>
									Select All
								</p>
							</div>
							{selectedItems?.length > 0 && (
								<Button
									variant="danger"
									onClick={handleBulkDelete}
									style={{
										fontSize: "2rem",
										width: "15rem",
										height: "5rem",
										marginTop: "1rem",
										marginLeft: "2rem",
									}}
								>
									Delete
								</Button>
							)}
						</div>
						<div style={{ display: "flex" }}>
							<div className="left_blk">
								<div className="search_blk">
									<div className="img_blk">
										<img src={search} alt="" />
									</div>
									<input
										type="search"
										value={searchText}
										placeholder="Search"
										onChange={(e) => {
											handleSearch(e.target.value);
										}}
									/>
								</div>
								<div>
									{filteredData?.length > 0 &&
										searchText === "" && (
											<img
												onClick={() =>
													handleClearFilter()
												}
												style={{
													width: "3rem",
													height: "3rem",
													position: "absolute",
													marginTop: "-1rem",
													marginLeft: "0.5rem",
													cursor: "pointer",
												}}
												src={filterClose}
												alt=""
											/>
										)}
									<div
										className="filter_blk pop_btn"
										onClick={handleFilter}
									>
										<img src={filters} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					{isEmptyArray(firms) ? (
						<div>
							<p
								style={{
									textAlign: "center",
									marginTop: "30rem",
									verticalAlign: "middle",
									fontSize: "large",
									color: "#114D96",
								}}
							>
								No Firms to show{" "}
							</p>
						</div>
					) : (
						isSearchSuccess &&
						filteredData?.length <= 0 && (
							<div>
								<p
									style={{
										textAlign: "center",
										marginTop: "30rem",
										verticalAlign: "middle",
										fontSize: "large",
										color: "#114D96",
									}}
								>
									No Record Found Against Your Search
								</p>
								<p
									onClick={handleClearFilter}
									style={{
										textAlign: "center",
										verticalAlign: "middle",
										fontSize: "large",
										color: "red",
										cursor: "pointer",
									}}
								>
									Clear Search
								</p>
							</div>
						)
					)}

					{(filteredData ? filteredData : firms)?.map((item: any) => {
						return (
							<RegisteredFirmsCard
								item={item}
								key={item.id}
								setDelete={handleDelete}
								setUpdate={handleUpdate}
								isSuperAdmin={false}
								isClient={false}
								isFirm={true}
								isSelected={selectedItems.includes(item.id)}
								onSelect={handleItemSelect}
								checkbox={true}
							/>
						);
					})}
					{!isLoading && !searchLoading && filteredData === null && (
						<ReactPagination
							setRowsPerPage={setRowsPerPage}
							setPages={setPageNumber}
							totalRecords={data?.total_records}
						/>
					)}
				</div>
			</section>
			<ScreenLoader show={isLoading || searchLoading} />
			<DeleteConfirmation
				showModal={deletePopUp}
				message={"Are you sure you want to delete the selected Firms?"}
				handleDelete={setDeleteFirm}
				hideModal={handleBulkDelete}
			/>
			<SuperAdminFilter
				active={filter}
				setActive={handleFilter}
				searchingResources={searching}
				lawyer={false}
				mediaType=""
			/>
			<AddNewFirm
				active={addFirm}
				setActive={handleSetAddFirm}
				isUpdate={false}
				isSuperAdmin={false}
			/>
			<ImportFromCSV
				active={importFirms}
				setActive={handleImportFirms}
				toImport="firm"
				refetch={setDeleteFirm}
			/>
			<ImportFromCSV
				active={bulkDeleteFirms}
				setActive={handleBulkDeleteFirms}
				toDelete="firm"
				refetch={setDeleteFirm}
			/>
		</Layout>
	);
};

export default RegisteredFirms;
