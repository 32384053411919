import { useEffect, useState } from "react";
import download from "../../assets/images/download.svg";
import { PdfIcon } from "../../icons";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";

const ResourcesCard = ({ item, update, isSelected, onSelect }: any) => {
	const [downloadLink, setDownloadLink] = useState("");

	useEffect(() => {
		if (item.pdf_link_status) {
			if (item.pdf_link) {
				setDownloadLink(item.pdf_link);
			}
		} else if (item.rich_text_status) {
			if (item?.law) {
				setDownloadLink(
					`https://elalaw.app/handbook_pdf.pdf?state=${
						item.state_id
					}&law=${item.law}&device=${"web"}`
				);
			} else {
				setDownloadLink(
					`https://elalaw.app/handbook_pdf.pdf?state=${
						item.state_id
					}&device=${"web"}`
				);
			}
		}
	}, []);

	const errorToast = (item: any) => {
		if (item.pdf_link_status) {
			if (!item.pdf_link) {
				toast.error(
					`Pdf Link Not Found for ${item?.state || item?.title}`
				);
			}
		}
	};

	const handleSelect = () => {
		onSelect(item?.pdf_link);
	};

	const handbookTopicFormat: any = {
		introduction: "INTRODUCTION",
		compensation: "COMPENSATION",
		discrimination_and_harassment: "DISCRIMINATION AND HARASSMENT",
		employee_injurie_and_workers_compensation:
			"EMPLOYEE INJURIES AND WORKERS COMPENSATION",
		health_and_safety: "HEALTH AND SAFETY",
		immigration_labor_migration: "IMMIGRATION LABOR MIGRATION",
		layoffs_workforce_reductions_redundancies_collective_dismissals:
			"LAYOFFS WORKFORCE REDUCTIONS REDUNDANCIES COLLECTIVE DISMISSALS",
		personnel_administration: "PERSONNEL ADMINISTRATION",
		privacy: "PRIVACY",
		additional_information: "ADDITIONAL INFORMATION",
	};
	const pdfName = handbookTopicFormat[item?.title]
		? handbookTopicFormat[item?.title]
		: item?.title;

	return (
		<div className='resource_card'>
			<div className='file_blk'>
				<Checkbox
					checked={isSelected}
					onClick={() => handleSelect()}
					classes={{ root: "custom-checkbox-root" }}
					style={{ marginRight: "5rem" }}
				/>
				<a
					href={downloadLink}
					download={item.title}
					target={item.pdf_link_status ? "_blank" : ""}
					rel='noreferrer'
					onClick={() => errorToast(item)}
					style={{ display: "flex", width: "115rem" }}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<div
							style={{
								display: "flex",
								width: '110rem'
							}}
						>
							<div className='img_blk'>
								<PdfIcon width={40} height={40} />
							</div>
							<div className='file_name_blk'>
								<h5 className='fw_500 transform'>
									{pdfName}{" "}
									{item?.state ? `(${item?.state})` : ""}
								</h5>
								<p>{item?.date}</p>
							</div>
						</div>
						<div className='download_blk'>
							<img src={download} alt='' />
						</div>
					</div>
				</a>
			</div>
		</div>
	);
};

export default ResourcesCard;
