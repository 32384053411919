import { useEffect, useState } from "react";
import chat from "../../assets/images/chat.png";
import wa from "../../assets/images/whatsapp.png";
import wa2 from "../../assets/images/wechat.png";
import { ArrowLeft } from "../../icons";

export default function LawyerContactShare({
	active,
	setActive,
	lawyer_contact,
	lawyer_name
}: any) {

	const [url, setUrl] = useState('');

	const message = `Hello, ${lawyer_name}.\nLet's connect on WhatsApp!`; // Replace this with your desired message

	const openWhatsAppWeb = () => {
		const phoneNumber = lawyer_contact?.replace(/\s/g, ""); // Replace this with the phone number you want to send the message to

		const link = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
			message
		)}`;

		setUrl(link);

	};

	useEffect(() => {
		openWhatsAppWeb()
	}, [])

	return (
		<>
			<section
				id='contactInfo_popUp'
				className={active ? "popup active" : "popup"}
				x-placement="bottom-start"
			>
				<div className='overlay' onClick={setActive}></div>
				<div className='table_dv'>
					<div className='table_cell'>
						<div className='contain'>
							<div className='row justify-content-center'>
								<div className='col-lg-4 col-md-5 col-sm-6'>
									<div className='_inner'>
										<button
											type='button'
											className='back_btn'
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h4 className='text-center mb-5 mt-5'>
											<img src={wa} alt="" className="dropimg" />
											<a href={url} target="_blank">WhatsApp</a>
										</h4>
										<h4 className='text-center'>
											<img src={wa2} alt="" className="dropimg" />
											<a href="https://web.wechat.com/" target="_blank">WeChat</a>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
