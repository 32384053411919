import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ArrowLeft } from "../../icons";
import ScreenLoader from "../Shared/ScreenLoader";
import TextInput from "../TextInput";
import ErrorText from "../Shared/ErrorText";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

interface EmailSendPropsType {
	showEmailModal: boolean;
	handleEmailModalClose: () => void;
	emails: any;
	handleSendEmail: any;
	isDisable?: boolean;
}

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};


// const initialValuesG = ;

function EmailSend({
	showEmailModal,
	handleEmailModalClose,
	emails,
	handleSendEmail,
	isDisable,
}: EmailSendPropsType) {
	const editor = useRef(null);
	const [toEmail, setToEmail] = useState("");
	const [toEmailsArray, setToEmailsArray] = useState<string[]>([]);
	const [isValid, setIsValid] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	/* const [initialValues, setInitialValues] = useState({
		content:
			JSON.parse(localStorage.getItem("email_template") || "{}")
				?.content || "",
		subject:
			JSON.parse(localStorage.getItem("email_template") || "{}")
				?.subject || "",
		emails:
			JSON.parse(
				localStorage.getItem("email_template") || "{}"
			)?.emails?.join(",") || "{}",
	}); */

	const [initialValues, setInitialValues] = useState({
		content:"",
		subject:"",
		emails:""
	});



	const {
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues,
		onSubmit: (values) => {
			sendButtonClicked(values);
		},
	});

	

	/* useEffect(() => {
		setInitialValues({
			content:
				JSON.parse(localStorage.getItem("email_template") || "{}")
					?.content,
			subject:
				JSON.parse(localStorage.getItem("email_template") || "{}")
					?.subject,
			emails:
				JSON.parse(
					localStorage.getItem("email_template") || "{}"
				)?.emails?.join(","),
		})
		if (
			JSON.parse(localStorage.getItem("email_template") || "{}")?.emails
		) {
			setToEmail(
				JSON.parse(
					localStorage.getItem("email_template") || ""
				)?.emails.join(",")
			);
		} else {
			setToEmail("");
		}
	}, []); */

	const sendButtonClicked = (values: any) => {
		if (!isValid) {
			handleSendEmail({
				emails: emails.length > 0 ? emails : toEmail.split(","),
				content: values?.content,
				subject: values?.subject,
			});
			setToEmail("");
			setToEmailsArray([]);
			resetForm();
			// localStorage.removeItem("email_template");
			console.log("i am clicked");
		} else {
			toast.error("Error! Please add valid emails");
		}
	};

	// Function to handle onchange event
	function handleEmailChange(event: any) {
		setToEmail(event.target.value.trim());
	}

	useEffect(() => {
		let emails = toEmail.split(",");
		emails.forEach((email: any) => {
			if (!isValidEmail(email) && email !== null && email !== "") {
				setIsValid(true);
				setErrorMessage(`Please enter a valid email: ${email}`);
			} else {
				// if (!toEmailsArray.includes(email)) {
				// 	// Update toEmailsArray state with valid emails
				// 	setToEmailsArray((prevEmails) => [...prevEmails, email]);
				// }
				setIsValid(false);
				setErrorMessage("");
			}
		});
	}, [toEmail]);

	/* 	 useEffect(() => {
		if (toEmail.includes(" ")) {
			// Split the input by space to get individual emails
			let email = toEmail.split(" ");
			
			if (!isValidEmail(email)) {
				setIsValid(true)
			}else {
				setIsValid(false)
			}
			
			email.forEach((email: any) => {
				// Perform basic email validation
				if (!toEmailsArray.includes(email)) {
					// Update toEmailsArray state with valid emails
					setToEmailsArray((prevEmails) => [...prevEmails, email]);
				}
			});
		}

		// setToEmail(toEmailsArray.join(', '))
	}, [toEmail]);  */

	// Basic email validation function
	function isValidEmail(email: any) {
		// Regular expression for basic email validation
		let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	function handleSaveTemplate() {
		let payload = {
			emails: emails.length > 0 ? emails : toEmail.split(","),
			content: values?.content,
			subject: values?.subject,
		};
		localStorage.setItem("email_template", JSON.stringify(payload));
	}

	function handleClearTemplate(){
		localStorage.removeItem("email_template");
		setInitialValues({
			content: "",
			subject: "",
			emails: ""
		})
		values.content = ""
		values.subject = ""
		values.emails = ""
	}
	return (
		<section
			id="add_lawyer_popup"
			className={showEmailModal ? "popup active" : "popup"}
		>
			<div className="overlay" onClick={handleEmailModalClose}></div>
			<div className="table_dv">
				<div className="table_cell">
					<div className="contain">
						<div className="row justify-content-center">
							<div className="col-lg-6 col-md-8 col-sm-10">
								<div className="inner_div">
									<button
										type="button"
										className="back_btn"
										onClick={handleEmailModalClose}
									>
										<ArrowLeft />
									</button>
									<h2 className="text-center">Email</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<div
											className="row"
											style={{
												justifyContent: "center",
												marginTop: 25,
											}}
										>
											<div className="form_blk_container">
												<div className="col-12 mb-5">
													<h5 className="fw_500 require">
														To
													</h5>

													<div className="form_blk">
														{isDisable ? (
															<TextInput
																placeholder="Email"
																type="text"
																name="to"
																value={emails}
																disabled={
																	isDisable
																}
															/>
														) : (
															<TextInput
																placeholder="Emails"
																type="text"
																name="to"
																value={toEmail}
																handleChange={(
																	e: any
																) =>
																	handleEmailChange(
																		e
																	)
																}
															/>
														)}

														{isValid && (
															<small>
																{errorMessage}
															</small>
														)}
													</div>
												</div>
											</div>
											<div className="form_blk_container">
												<div className="col-12 mb-5">
													<h5 className="fw_500 require">
														Subject
													</h5>

													<div className="form_blk">
														<TextInput
															placeholder="Subject"
															type="text"
															name="subject"
															value={
																values?.subject ||
																""
															}
															handleChange={(
																e: any
															) => {
																setFieldValue(
																	"subject",
																	e.target
																		.value
																);
															}}
														/>
													</div>
												</div>
											</div>

											<div className="form_blk_container">
												<div className="col-12">
													<div className="form_blk">
														<JoditEditor
															ref={editor}
															value={
																values?.content ||
																""
															}
															className="editor-class"
															onBlur={(
																newContent
															) => {
																setFieldValue(
																	"content",
																	newContent
																);
															}} // preferred to use only this option to update the content for performance reasons
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												type="submit"
											>
												{false ? (
													<ScreenLoader
														show={false}
													/>
												) : (
													"Send Email"
												)}
											</button>
										</div>
										{/* <div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												onClick={handleSaveTemplate}
											>
												{false ? (
													<ScreenLoader
														show={false}
													/>
												) : (
													"Save Template"
												)}
											</button>
										</div> */}
										{/* <div className="btn_blk mt-5 pt-3">
											<button
												className="site_btn w-100"
												onClick={handleClearTemplate}
											>
												{false ? (
													<ScreenLoader
														show={false}
													/>
												) : (
													"Clear Template"
												)}
											</button>
										</div> */}
									</form>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default EmailSend;
