import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { MenuItem, Select } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import NoImage from '../../../assets/images/noImage.png';
import { ArrowLeft } from '../../../icons';
import { IAddContact } from '../../../interfaces/Admin';
import { GetLoginUserData } from '../../../redux/selectors/Common';
import {
	useCreateContactMutation,
	useGetAllLawyersQuery,
} from '../../../redux/services/Firm/FirmLawyer';
import { addContactSchema } from '../../../utills/YupValidationSchemas';
import ErrorText from '../../Shared/ErrorText';
import ScreenLoader from '../../Shared/ScreenLoader';
import TextInput from '../../TextInput';
import './AddContact.scss';

interface AddContact {
	active: boolean;
	setActive: any;
	contact?: IAddContact;
	updateContact?: boolean;
	setUpdateContact?: any;
	newLawyer?: boolean;
	disable?: boolean;
}

export default function AddContact(props: AddContact) {
	const fileInput = useRef<HTMLInputElement>(null);

	const [formImage, setFormImage] = useState<any>('');

	const [formImageError, setFormImageError] = useState<any>('');

	const [uploadedImage, setUploadedImage] = useState<any>(null);

	const [
		addContact,
		{ data: addContactData, isSuccess, isError, isLoading },
	] = useCreateContactMutation();

	const userData: any = GetLoginUserData();

	const { data: lawyerNames } = useGetAllLawyersQuery(null);

	const [lawyer, setLawyer] = useState<any>(null);

	const handleAddContact = (values: any) => {
		if (userData?.lawyer?.id) {
			values.lawyer_id = userData.lawyer.id;
		}

		if (props.updateContact) {
			addContact({
				...values,
				id: props?.contact?.id,
				profile_image: formImage,
			});
		} else {
			addContact({
				...values,
				profile_image: formImage,
			});
		}
	};
	useEffect(() => {
		if (isSuccess) {
			setUploadedImage(NoImage);
			resetForm();
			handleClose();
			toast.success(addContactData?.message);
			setLawyer(null);
			props.setUpdateContact(true);
		} else if (addContactData?.message === 'Something wents wrong') {
			handleClose();
			toast.error('Something went wrong, Please try again');
		}
	}, [isSuccess, isError]);

	const handleClose = () => {
		props.setActive();
	};

	const {
		values,
		errors,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		touched,
		resetForm,
	} = useFormik({
		validationSchema: addContactSchema,
		enableReinitialize: true,
		initialValues: {
			email: props.updateContact ? props?.contact?.email : '',
			lawyer_id: props.contact?.lawyer_id || '',
			name: props.updateContact ? props?.contact?.name : '',
			profile_image: props.updateContact
				? props?.contact?.profile_image
				: '',
			phone: props.updateContact ? props?.contact?.phone : '',
		},
		onSubmit: (values) => {
			handleAddContact(values);
		},
	});

	const handleFileUpload = (e: any) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];

		if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
			return;
		}

		setFormImage(file);
		setUploadedImage(URL.createObjectURL(file));
		setFormImageError('');
	};

	useEffect(() => {
		if (
			props?.contact?.profile_image !== '' &&
			props?.updateContact === true
		) {
			setFormImage(props?.contact?.profile_image);
		}

		if (props?.contact?.lawyer_id && lawyerNames) {
			const seletedLawyer = lawyerNames.find(
				(obj: any) => obj.id === props.contact?.lawyer_id
			);
			setLawyer(seletedLawyer);
		}
	}, [props.contact]);

	useEffect(() => {
		if (props?.contact?.lawyer_id && lawyerNames) {
			const seletedLawyer = lawyerNames.find(
				(obj: any) => obj.id === props.contact?.lawyer_id
			);
			setLawyer(seletedLawyer);
		}
	}, [lawyerNames]);

	return (
		<section
			id='add_lawyer_popup'
			className={props.active ? 'popup active' : 'popup'}
		>
			<div className='overlay' onClick={handleClose}></div>
			<div className='table_dv'>
				<div className='table_cell'>
					<div className='contain'>
						<div className='row justify-content-center'>
							<div className='col-lg-6 col-md-8 col-sm-10'>
								<div className='inner_div'>
									<button
										type='button'
										className='back_btn'
										onClick={props.setActive}
									>
										<ArrowLeft />
									</button>
									<h2 className='text-center'>
										{!props.updateContact
											? 'Add Contact'
											: props.disable
											? 'Contact Details'
											: 'Update Contact'}
									</h2>

									<form
										onSubmit={(e) => {
											e.preventDefault();

											if (formImage) {
												handleSubmit();
											} else {
												setFormImageError(
													'Image is Required'
												);
											}
										}}
									>
										<div className='ico fill round dp dp-image'>
											<img
												src={
													props?.contact
														?.profile_image &&
													!uploadedImage
														? props?.contact
																?.profile_image
														: uploadedImage ||
														  NoImage
												}
												alt='lawyerImage'
											/>
											{!props.disable && (
												<div
													className='edit-icon'
													onClick={() =>
														fileInput?.current?.click()
													}
												>
													<CameraAltIcon className='dp-icon' />
												</div>
											)}
											<input
												ref={fileInput}
												type='file'
												hidden
												onChange={handleFileUpload}
											/>
										</div>
										<div className='text-center'>
											<h5
												style={{
													color: 'red',
												}}
											>
												{formImageError}
											</h5>
										</div>
										{/* ------------------------------------------------------------------------------------------------------------------
                                        Name And Email Row
------------------------------------------------------------------------------------------------------------------ */}
										<div
											className='row'
											style={{
												justifyContent: 'center',
												marginTop: 25,
											}}
										>
											<div className='form_blk_container'>
												<div className='input_field_div'>
													<h5 className='fw_500 require'>
														Name
													</h5>
													<div className='form_blk'>
														<TextInput
															placeholder='eg: John Wick'
															disabled={
																props.disable
															}
															type='text'
															name='name'
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.name}
														/>
													</div>
													<ErrorText
														text={errors.name}
														isTouched={touched.name}
													/>
												</div>
												<div className='input_field_div'>
													<h5 className='fw_500 require'>
														Email Address
													</h5>
													<div className='form_blk'>
														<TextInput
															placeholder='eg: sample@gmail.com'
															disabled={
																props.disable
															}
															type='email'
															name='email'
															handleChange={
																handleChange
															}
															handleBlur={
																handleBlur
															}
															value={values.email}
														/>
													</div>
													<ErrorText
														text={errors.email}
														isTouched={
															touched.email
														}
													/>
												</div>
											</div>

											{!props.disable &&
												userData?.firm && (
													<div
														style={{
															paddingLeft:
																'4.5rem',
															paddingRight:
																'4.5rem',
														}}
													>
														<h5 className='fw_500 require my-4'>
															Lawyer
														</h5>
														<Select
															disabled={
																props.disable
															}
															value={
																lawyer?.name
																	? lawyer?.name
																	: ''
															}
															onChange={(
																event: any
															) => {
																console.log(
																	event.target
																		.value
																);

																setLawyer(
																	event.target
																		?.value
																);
																setFieldValue(
																	'lawyer_id',
																	event.target
																		?.value
																		?.id
																);
															}}
															style={{
																width: '100%',
																lineHeight:
																	'3rem',
															}}
															displayEmpty
															renderValue={() =>
																lawyer?.name
																	? lawyer?.name
																	: 'Select Lawyer'
															}
														>
															{lawyerNames &&
																lawyerNames.map(
																	(
																		item: any
																	) => (
																		<MenuItem
																			key={
																				item.id
																			}
																			value={
																				item
																			}
																			style={{
																				fontSize: 17,
																				alignSelf:
																					'center',
																			}}
																		>
																			{
																				item.name
																			}
																		</MenuItem>
																	)
																)}
														</Select>
														<ErrorText
															text={
																errors.lawyer_id
															}
															isTouched={
																touched.lawyer_id
															}
														/>
													</div>
												)}

											{/* ------------------------------------------------------------------------------------------------------------------
                                            Phone Number
------------------------------------------------------------------------------------------------------------------ */}
											<div className='form_blk_container'>
												<div className='col-12'>
													<h5 className='fw_500 require my-4'>
														Phone
													</h5>
													<div className='form_blk'>
														<PhoneInput
															disabled={
																props.disable
															}
															autoFormat={true}
															country={'us'}
															value={values.phone}
															onChange={(
																value
															) => {
																setFieldValue(
																	'phone',
																	value
																);
															}}
														/>
														<ErrorText
															text={errors.phone}
															isTouched={
																touched.phone
															}
														/>
													</div>
												</div>
											</div>
										</div>
										{!props.disable && (
											<div className='btn_blk mt-5 pt-3'>
												<button
													className='site_btn w-100'
													type='submit'
												>
													{isLoading ? (
														<ScreenLoader
															show={isLoading}
														/>
													) : props.updateContact ? (
														'Update Contact'
													) : (
														'Add Contact'
													)}
												</button>
											</div>
										)}
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
