import { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import ReferalCard from "../../../../components/ReferalCard";
import ScreenLoader from "../../../../components/Shared/ScreenLoader";
import TopBlock from "../../../../components/TopBlock";
import {
	useGetExportReferralListQuery,
	useGetReferralListQuery,
} from "../../../../redux/services/Firm/FirmLawyer";

// import "./paginator.scss";
import { Checkbox } from "@mui/material";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import ReactPagination from "../../../../components/ReactPagination/ReactPagination";
import { useDeleteReferralMutation } from "../../../../redux/services/Lawyer/DashboardApi";
import "./referals.scss";
import AnalyticsTile from "../../../../components/AnalyticsTile";
import { useGetAnalyticsDataQuery } from "../../../../redux/services/Dashboard";
import icon4 from "../../../../assets/images/icon4.svg";
import { useLocation } from 'react-router-dom';

const ManageReferrals = () => {
	const [selectAll, setSelectAll] = useState(false);
	const [selectedItems, setSelectedItems] = useState<any>([]);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageNumber, setPageNumber] = useState(1);
	const [deleteReferralCall, setDeleteReferralCall] = useState(false);
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const registeredParam = params.get('registered');
	const thisMonthParam = params.get('this_month');
	const queryParams = {
		...(registeredParam && { registered: registeredParam }),
		...(thisMonthParam && { this_month: thisMonthParam })
	  };

	const { data, isLoading, refetch } = useGetReferralListQuery({
		rowsPerPage: rowsPerPage,
		pageNumber: pageNumber,
		...queryParams
	});

	const {
		data: analyticsData,
		isLoading: isAnalyticsLoading,
		isSuccess: isAnalyticsSuccess,
	} = useGetAnalyticsDataQuery({
		refetchOnMountOrArgChange: true,
	});

	const { data: referralsToExport } = useGetExportReferralListQuery({});

	useEffect(() => {
		refetch();
	}, []);

	const [deleteReferral, { isSuccess, isError }] =
		useDeleteReferralMutation();

	const handleSelectAllChange = () => {
		if (selectAll) {
			setSelectedItems([]);
		} else {
			setSelectedItems(
				data?.referrals?.map((item: { id: any }) => item.id)
			);
		}

		setSelectAll(!selectAll);
	};

	const handleBulkDelete = () => {
		setDeletePopUp(!deletePopUp);
	};

	const handleSelect = (id: any) => {
		handleItemSelect(id);
	};

	const handleItemSelect = (itemId: any) => {
		if (selectedItems.includes(itemId)) {
			setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
		} else {
			setSelectedItems([...selectedItems, itemId]);
		}
	};

	const handleExportCsv = () => {
		const csvData = createCsvData(referralsToExport);
		const blob = new Blob([csvData], { type: "text/csv" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.download = "exported-admin-referrals.csv";
		link.href = url;
		link.click();
	};

	const createCsvData = (data: any) => {
		let csvData = `"Send Date","From Region","From Jurisdiction","From Firm","To Region","To Jurisdiction","To Firm",Message/Description,"Referral Tpe","Type of matter"\n`;

		data?.referrals?.forEach((item: any) => {
			csvData =
				csvData +
				`"${item.send_date}","${item.from_region}","${item.from_jurisdiction}","${item.from_firm}","${item.to_region}","${item.to_jurisdiction}","${item.to_firm}","${item.message}"\n`;
		});

		return csvData;
	};

	useEffect(() => {
		if (deleteReferralCall) {
			deleteReferral({ id: selectedItems, admin: true });
			setDeleteReferralCall(false);
		}
		if (isSuccess) {
			refetch();
			setSelectedItems([]);
			toast.success("Referrals Deleted Successfully");
			if (selectAll) {
				setSelectAll(!selectAll);
			}
		}
		if (isError) {
			toast.error(
				"Could not delete referrals at the moment, Please try again"
			);
		}
		setDeletePopUp(false);
	}, [deleteReferralCall, isSuccess, isError]);

	return (
		<Layout>
			<section id="admin_manage_referrals" className="sec_pad_btm ">
				<div className="contain sm">
					<div id="dashboard">
						<AnalyticsTile
							title="Referrals"
							icon={icon4}
							path="/firm/super-admin-manage-referrals"
							count={analyticsData?.referrals?.total_count}
							currentMonth={
								analyticsData?.referrals?.current_month_count
							}
							percentage={
								analyticsData?.referrals?.percentage_difference
							}
						/>
					</div>
					<TopBlock title="Referrals" url="dashboard" />
					<div
						className="search_container"
						style={{
							justifyContent: "space-between",
							backgroundColor: "#63a744",
							alignItems: "center",
							padding: "1rem",
							borderRadius: "1rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									marginLeft: "0.5rem",
								}}
							>
								<Checkbox
									checked={selectAll}
									classes={{ root: "custom-checkbox-root" }}
									onClick={() => {
										handleSelectAllChange();
									}}
								/>
								<p
									style={{
										fontSize: "2rem",
										lineHeight: "7rem",
										marginLeft: "0.5rem",
									}}
								>
									Select All
								</p>
							</div>
						</div>
						<div>
							{selectedItems?.length > 1 && (
								<Button
									variant="danger"
									onClick={handleBulkDelete}
									style={{
										fontSize: "2rem",
										width: "15rem",
										height: "5rem",
										marginRight: "2rem",
									}}
								>
									Delete
								</Button>
							)}
							<Button
								variant="primary"
								onClick={handleExportCsv}
								className="export_button"
							>
								Export All Referrals History In CSV
							</Button>
						</div>
					</div>
					<ScreenLoader show={isLoading} />
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							margin: "auto",
							width: "100%",
						}}
					>
						{data?.referrals?.map((item: any) => {
							return (
								<ReferalCard
									item={item}
									key={item.id}
									refetch={refetch}
									checkbox={true}
									handleSelect={handleSelect}
									isSelected={selectedItems.includes(item.id)}
									admin={true}
								/>
							);
						})}
						{!isLoading && (
							<ReactPagination
								setRowsPerPage={setRowsPerPage}
								setPages={setPageNumber}
								totalRecords={data?.total_records}
							/>
						)}
					</div>
					{/* {referralsData?.referrals?.length ? (
						
						<PaginatedItems
							itemsPerPage={10}
							items={referralsData?.referrals}
							outgoingReferal={true}
							refetch={refetch}
                            admin={true}
						/>
					) : (
						<p
							style={{
								fontSize: "2rem",
								textAlign: "center",
								marginTop: "20rem",
							}}
						>
							{"No Referrals To Show"}
						</p>
					)} */}
					<DeleteConfirmation
						showModal={deletePopUp}
						message={
							"Are you sure you want to delete the selected Referrals?"
						}
						handleDelete={setDeleteReferralCall}
						hideModal={handleBulkDelete}
					/>
				</div>
			</section>
		</Layout>
	);
};
export default ManageReferrals;
