import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";
import groupPerson from "../../assets/images/groupPerson.svg";
import messageIcon from "../../assets/images/messageIcon.svg";
import phoneIcon from "../../assets/images/phoneIcon.svg";
import chat from "../../assets/images/chat.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import {
	useDeleteContactMutation,
	useDeleteFirmLawyerMutation,
	useSendLawyerEmailMutation,
} from "../../redux/services/Firm/FirmLawyer";
import DeleteConfirmation from "../DeleteConfirmationModal/DeleteConfirmationModal";
import AddFirmLawyers from "../Popup/AddFirmLawyer/AddFirmLawyer";
import LawyerComposeReferral from "../Popup/LawyerComposeReferral";
import LawyerContactInfo from "../Popup/LawyerContactInfo";
import AddContact from "../Popup/AddNewContact/AddContact";
import LawyerContactShare from "../Popup/LawyerContactShare";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import EmailSend from "../EmailSend";

const LawyerDirectoryCard = ({
	item,
	firm,
	setDelete,
	setUpdate,
	isSelected,
	onSelect,
	checkbox,
	newLawyer,
	totalRecords,
	isContact,
	setShowAddModel,
	showAddModal,
	hadnleContactAdded,
}: any) => {
	const currentUrl = window?.location?.href;
	const [composeRef, setComposeRef] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [editLawyer, setEditLawyer] = useState(false);
	const [editContact, setEditContact] = useState(false);
	const [lawyerDelete, setLawyerDelete] = useState(false);
	const [contactDelete, setContactDelete] = useState(false);
	const [deleteLawyerPopUp, setDeleteLawyerPopup] = useState(false);
	const [contactInfo, setContactInfo] = useState(false);
	const [contactShare, setContactShare] = useState(false);
	const [visible, setVisible] = useState(false);
	const [emailInfo, setEmailInfo] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState<string[]>([]);

	const [deleteFirmLawyer, { data, isSuccess, isError }] =
		useDeleteFirmLawyerMutation();

	const [
		deleteContact,
		{ data: contactData, isSuccess: contactSuccess, isError: contactError },
	] = useDeleteContactMutation();

	const [
		sendEmail,
		{ data: emailData, isSuccess: isSendEmailSuccess, isError: isSendEmailError },
	] = useSendLawyerEmailMutation();

	const handleComposeRef = () => {
		setComposeRef(!composeRef);
	};

	const handleContactInfo = () => {
		setContactInfo(!contactInfo);
	};

	const handleEmailInfo = () => {
		setEmailInfo(!emailInfo);
	};

	const handleDeleteLawyer = () => {
		setDeleteLawyerPopup(!deleteLawyerPopUp);
	};
	const handleUpdateLawyer = () => {
		setEditLawyer(!editLawyer);
	};

	const handleUpdateContact = () => {
		setEditContact(!editContact);
	};

	const handleContactShare = () => {
		setContactShare(!contactShare);
	};

	useEffect(() => {
		if (isSuccess) {
			setDelete();
			toast.success("Lawyer Deleted Successfully");
		}
		if (isError) {
			toast.error("Error! Can not delete the lawyer. Please try Again");
		}
	}, [isSuccess, isError]);

	useEffect(() => {
		if (isSendEmailSuccess) {
			toast.success("Email Sent Successfully");
			handleEmailModalClose();
		}
		if (isSendEmailError) {
			toast.error(
				"Could not send email, Please try again"
			);
		}
	}, [isSendEmailSuccess, isSendEmailError]);

	useEffect(() => {
		if (lawyerDelete) {
			deleteFirmLawyer({
				id: item.id,
			});
			setLawyerDelete(false);
		}
	}, [lawyerDelete]);

	useEffect(() => {
		if (contactDelete) {
			deleteContact({
				id: item.id,
			});
			setContactDelete(false);
		}
	}, [contactDelete]);

	useEffect(() => {
		if (contactSuccess) {
			setDelete();
			toast.success("Contact Deleted Successfully");
		}
		if (contactError) {
			toast.error("Error! Can not delete the contact. Please try Again");
		}
	}, [contactSuccess, contactError]);

	const handleSelect = () => {
		onSelect(item?.id);
	};
	const handleViewLawyer = () => {
		setVisible(!visible);
	};

	const handleSendEmail = (payload: any) => {
		sendEmail(payload)
	};

	const handleEmailModalOpen = (email: string) => {
		setSelectedEmail([...selectedEmail, email]);
		setShowEmailModal(true);
	};
	const handleEmailModalClose = () => {
		setShowEmailModal(false);
		setSelectedEmail([]);
	};
	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 991px)");

		const handleMediaQueryChange = (e: any) => {
			setIsMobile(e.matches);
		};

		// Initial check
		handleMediaQueryChange(mediaQuery);

		// Add listener for changes
		mediaQuery.addEventListener("change", handleMediaQueryChange);

		// Clean up listener when component unmounts
		return () => {
			mediaQuery.removeEventListener("change", handleMediaQueryChange);
		};
	}, []);

	return (
		<>
			<div
				className="lawyer_Directory_card"
				style={{
					flexDirection: isMobile ? "column" : "row",
				}}
			>
				{checkbox && (
					<Checkbox
						checked={isSelected}
						onClick={() => handleSelect()}
						classes={{ root: "custom-checkbox-root" }}
					/>
				)}
				<div className="profile_blk">
					<div className="img_blk">
						<Avatar
							alt="Remy Sharp"
							src={item?.profile_image || item.image_url}
							sx={{ width: 56, height: 56 }}
						/>
					</div>
					<div className="text_blk">
						<h5 className="fs_5 fw_500">{item.name}</h5>
						{!isContact && (
							<p>Jurisdiction: {item?.jurisdiction}</p>
						)}
					</div>
				</div>
				<div
					className="phone_email"
					style={{ display: isMobile && checkbox ? "none" : "flex" }}
				>
					{!currentUrl.includes("/lawyers") && (
						<div className="phone">
							<h5 className="fs_5 fw_500">Phone no</h5>
							<p className="fw_400">
								{item?.phone || "Unavailable"}
							</p>
						</div>
					)}
					<div className="email">
						<h5 className="fs_5 fw_500">Email</h5>
						<p className="fw_400">{item?.email}</p>
					</div>
					{firm  && <div>
						<div
							style={{
								borderRadius: "100%",
								height: "24px",
								width: "24px",
								backgroundColor:
									item?.device?.length > 2
										? "#00FF00"
										: "red",
							}}
						></div>
					</div>}
					{currentUrl.includes("/lawyers") && (
						<div className="email">
							<h5 className="fs_5 fw_500">Firm</h5>
							<div
								style={{
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
							>
								<a
									href={item.firm_url}
									target="_blank"
									className="fw_400"
									rel="noreferrer"
								>
									{item?.firm_name}
								</a>
							</div>
						</div>
					)}
				</div>
				{!firm ? (
					<ul className="icons">
						<li>
							<button
								type="button"
								className="icon_blk"
								onClick={handleContactShare}
							>
								<img src={chat} alt="" />
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								onClick={handleContactInfo}
							>
								<img src={phoneIcon} alt="" />
							</button>
						</li>
						<li>
							<button type="button" className="icon_blk">
								<img
									src={messageIcon}
									alt=""
									onClick={handleEmailInfo}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								onClick={handleComposeRef}
							>
								<img src={groupPerson} alt="" />
							</button>
						</li>
					</ul>
				) : (
					<ul className="edit_icons">
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={() => handleEmailModalOpen(item.email)}
							>
								<ForwardToInboxIcon
									sx={{
										color: "#eaa221",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={handleViewLawyer}
							>
								<VisibilityIcon
									sx={{
										color: "grey",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={
									isContact
										? handleUpdateContact
										: handleUpdateLawyer
								}
							>
								<BorderColorIcon
									sx={{
										color: "#63A744",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
						<li>
							<button
								type="button"
								className="icon_blk"
								style={{ cursor: "pointer" }}
								onClick={handleDeleteLawyer}
							>
								<DeleteIcon
									sx={{
										color: "red",
										fontSize: "3.2rem !important",
									}}
								/>
							</button>
						</li>
					</ul>
				)}
				{/* <div className="pop_btn" onClick={handleLawyerInfo}></div> */}
			</div>
			
			<EmailSend
				emails={selectedEmail}
				showEmailModal={showEmailModal}
				handleEmailModalClose={handleEmailModalClose}
				handleSendEmail={handleSendEmail}
				isDisable={true}
			/>
			{/* for share */}
			<LawyerContactShare
				active={contactShare}
				setActive={handleContactShare}
				lawyer_name={item?.name}
				lawyer_contact={item?.phone}
			/>
			<LawyerComposeReferral
				active={composeRef}
				setActive={handleComposeRef}
				lawyer={item}
				totalRecords={totalRecords}
			/>
			{/* for contactInfo */}
			<LawyerContactInfo
				active={contactInfo}
				setActive={handleContactInfo}
				lawyer_name={item?.name}
				lawyer_contact={item?.phone}
			/>
			{/* for EmailInfo */}
			<LawyerContactInfo
				active={emailInfo}
				setActive={handleEmailInfo}
				lawyer_name={item?.name}
				lawyer_contact={item?.email}
				emailPopUp={true}
			/>
			{isContact ? (
				<>
					<AddContact
						active={editContact}
						setActive={handleUpdateContact}
						contact={item}
						updateContact={true}
						setUpdateContact={setUpdate}
						newLawyer={newLawyer}
					/>
					<AddContact
						active={showAddModal}
						setActive={setShowAddModel}
						updateContact={false}
						setUpdateContact={hadnleContactAdded}
					/>
					<AddContact
						active={visible}
						setActive={handleViewLawyer}
						contact={item}
						newLawyer={newLawyer}
						disable={true}
						setUpdateContact={true}
					/>
					<DeleteConfirmation
						message={
							"Are you sure you want to delete this Contact ?"
						}
						showModal={deleteLawyerPopUp}
						handleDelete={setContactDelete}
						hideModal={handleDeleteLawyer}
					/>
				</>
			) : (
				<>
					<AddFirmLawyers
						active={editLawyer}
						setActive={handleUpdateLawyer}
						lawyer={item}
						updateLawyer={true}
						setUpdateLawyer={setUpdate}
						newLawyer={newLawyer}
					/>
					<AddFirmLawyers
						active={visible}
						setActive={handleViewLawyer}
						lawyer={item}
						newLawyer={newLawyer}
						disable={true}
						updateLawyer={true}
					/>
					<DeleteConfirmation
						message={
							"Are you sure you want to delete this Lawyer ?"
						}
						showModal={deleteLawyerPopUp}
						handleDelete={setLawyerDelete}
						hideModal={handleDeleteLawyer}
					/>
				</>
			)}
		</>
	);
};

export default LawyerDirectoryCard;
