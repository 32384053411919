import { isEmptyArray } from "formik";
import { ArrowLeft } from "../../../icons";
import {
	useGetContactsQuery
} from "../../../redux/services/Lawyer/DashboardApi";
import ScreenLoader from "../../Shared/ScreenLoader";
import "./ContactCard/ContactCard.scss";
import ContactCard from "./ContactCard/index";

export default function ContactModal({
	active,
	setActive,
	referralObject,
	setReferralObject,
	isCcEmailAdd,
}: any) {

	const { data: contactsData, isLoading, refetch } = useGetContactsQuery({});

	return (
		<>
			<section
				id='filter_popup'
				className={active ? "popup active" : "popup"}
			>
				<div className='overlay' onClick={setActive}></div>
				<div className='table_dv'>
					<div className='table_cell'>
						<div className='contain'>
							<div className='row justify-content-center'>
								<div className='col-lg-6 col-md-8 col-sm-10'>
									<div className='_contact_modal'>
										<button
											type='button'
											className='back_btn'
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h2 className='text-center mb-5'>
											Contacts List
										</h2>
										<h5 className='text-center mb-5'>
											Total contacts: {contactsData?.contacts?.length}
										</h5>
										{false && <ScreenLoader show={true} />}
										<div style={{ width: "100%" }}>
											{!isEmptyArray(
												contactsData?.contacts
											)
												? contactsData?.contacts?.map(
														(item: any) => {
															return (
																<ContactCard
																	item={item}
																	key={
																		item.id
																	}
																	totalRecords={
																		contactsData?.total_records
																	}
																	checkbox={
																		true
																	}
																	firm={true}
																	isContact={
																		true
																	}
																	addEmail={
																		setActive
																	}
																	referralObject={
																		referralObject
																	}
																	setReferralObject={
																		setReferralObject
																	}
																	isCcEmailAdd={isCcEmailAdd}
																/>
															);
														}
												  )
												: !isLoading && (
														<div className='text-center mt-5'>
															<h3>
																No Records Found
															</h3>
														</div>
												  )}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
