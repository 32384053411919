import { useEffect, useState } from "react";
import NoImage from "../../assets/images/noImage.png";
import { ArrowLeft } from "../../icons";
import Image from "material-ui-image";
import { useGetLawyersQuery } from "../../redux/services/Lawyer/DashboardApi";
import { useGetRole } from "../../redux/selectors/Common";

export default function LawyerList({ active, setActive, referral, totalRecords }: any) {
	// const data = useAppSelector((state: any) => state.authAPi.queries.getLawyers.data);
	// console.log(data)
	const role = useGetRole();
	const { data, isLoading } = useGetLawyersQuery({ rowsPerPage:totalRecords || 10, pageNumber:1, role });
	const [lawyers, setLawyers]: any = useState([]);
	useEffect(() => {
		if (data) {
			setLawyers(data?.lawyers || []);
		}
	}, []);
	return (
		<>
			<section
				id="filter_popup"
				className={active ? "popup active" : "popup"}
			>
				<div className="overlay" onClick={setActive}></div>
				<div className="table_dv">
					<div className="table_cell">
						<div className="contain">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-8 col-sm-10">
									<div className="_inner">
										<button
											type="button"
											className="back_btn"
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h2 className="text-center mb-5">
											Lawyer List
										</h2>
										{lawyers?.map((lawyer: any) => {
											return (
												<div
													className="lawyer_list_block"
													key={lawyer.id}
												>
													<div className="user_blk">
														<div className="user_profile_blk">
															<Image
																src={
																	lawyer.profile_image
																		? lawyer.profile_image
																		: NoImage
																}
															/>
														</div>
														<div className="user_name_blk">
															<p className="fw_600">
																{lawyer.name}
															</p>
															<p className="fw_400">
																{
																	lawyer.member_type
																}
															</p>
														</div>
													</div>
													<button className="site_btn send_btn">
														Send
													</button>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}