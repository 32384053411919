import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { ArrowLeft } from '../../icons';
import LawyerReferralSent from './LawyerReferralSent';
import { Formik } from 'formik';
import TextArea from '../../components/TextArea';
import { referralMessageSchema } from '../../utills/YupValidationSchemas';
import ErrorText from '../Shared/ErrorText';
import { useGetRole } from '../../redux/selectors/Common';
import { useComposeReferralMutation } from '../../redux/services/Lawyer/DashboardApi';
import { GetLoginUserData } from '../../redux/selectors/Common';
import TextInput from '../TextInput';
import JoditEditor from 'jodit-react';
import ScreenLoader from '../Shared/ScreenLoader';
import { Button, Row } from 'react-bootstrap';
import phoneIcon from '../../assets/images/phoneIcon.svg';
import ContactModal from './ContactModal/ContactModal';

export default function LawyerComposeReferral({
	active,
	setActive,
	lawyer,
	totalRecords,
}: any) {
	const editor = useRef(null);
	const [sentRef, setSentRef] = useState(false);
	const role = useGetRole();
	const userData: any = GetLoginUserData();
	const [referral, setReferral] = useState<any>('');
	const [isLoading, setIsLoading] = useState(false);
	const [referralObject, setReferralObject] = useState({
		cc: '',
		bcc: '',
		description: '',
	});
	const [contactModal, setContactModal] = useState(false);
	const [isCcEmailAdd, setIsCcEmailAdd] = useState(false);

	const [composeReferral, { data, isSuccess, isError }] =
		useComposeReferralMutation();

	const handleSentRef = () => {
		setSentRef(!sentRef);
	};

	const handleSubmit = async (values: any) => {
		setIsLoading(true);
		values.role = role;
		if (role === 'firm') {
			values.userId = userData.firm?.id;
			values.firmReferral = true;
		} else {
			values.userId = userData.lawyer?.id;
		}
		await composeReferral(values);
		setReferral(values);
		setIsLoading(false);
		setReferralObject({ cc: '', bcc: '', description: '' });
	};

	const handleContactModal = () => {
		setContactModal(!contactModal);
	};

	useEffect(() => {
		if (isSuccess) {
			setActive(!active);
			setSentRef(!sentRef);
		}
		if (isError) {
			setActive(!active);
			toast.error('Something went wrong');
		}
	}, [isSuccess, isError]);

	return (
		<>
			<section
				id='filter_popup'
				className={active ? 'popup active' : 'popup'}
			>
				<div className='overlay' onClick={setActive}></div>
				<div className='table_dv'>
					<div className='table_cell'>
						<div className='contain'>
							<div className='row justify-content-center'>
								<div className='col-lg-6 col-md-8 col-sm-10'>
									<div className='_inner'>
										<button
											type='button'
											className='back_btn'
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h2 className='text-center mb-5'>
											Compose Referral
										</h2>
										{isLoading && (
											<ScreenLoader show={true} />
										)}
										<Formik
											initialValues={{
												id: lawyer.id,
												message:
													referralObject.description,
												userType: role,
												cc: referralObject.cc,
												bcc: referralObject.bcc,
											}}
											validationSchema={
												referralMessageSchema
											}
											onSubmit={(values) =>
												handleSubmit(values)
											}
										>
											{({
												values,
												handleChange,
												handleBlur,
												handleSubmit,
												setFieldValue,
												errors,
												touched,
											}) => (
												<form
													onSubmit={handleSubmit}
													className='auth-form'
												>
													<div>
														<div className='row mb-5'>
															<div className='col-12'>
																<h5>
																	To Email
																</h5>
																<div className='form_blk'>
																	<TextInput
																		placeholder=''
																		type='text'
																		// name="name"
																		handleChange={() => {}}
																		handleBlur={() => {}}
																		value={
																			lawyer?.email
																		}
																		disabled={
																			true
																		}
																	/>
																</div>
															</div>
														</div>
														<div className='row mb-5'>
															<div className='col-12'>
																<h5>
																	{
																		'Cc (Optional)'
																	}
																</h5>
																<div
																	className='form_blk'
																	style={{
																		flexDirection:
																			'row',
																		display:
																			'flex',
																	}}
																>
																	<TextInput
																		className='input input-placeholder'
																		placeholder='Press the button to load Contacts | Enter space to add multiple emails'
																		type='text'
																		name='cc'
																		handleChange={(
																			e: any
																		) => {
																			setReferralObject(
																				{
																					...referralObject,
																					[e
																						.target
																						.name]:
																						e.target.value.replace(
																							/ /g,
																							','
																						),
																				}
																			);

																			setFieldValue(
																				'cc',
																				e.target.value.replace(
																					/ /g,
																					','
																				)
																			);
																		}}
																		handleBlur={
																			handleBlur
																		}
																		value={
																			referralObject.cc
																		}
																	/>
																	<Button
																		onClick={() => {
																			setIsCcEmailAdd(
																				true
																			);
																			handleContactModal();
																		}}
																		style={{
																			backgroundColor:
																				'white',
																		}}
																	>
																		<img
																			src={
																				phoneIcon
																			}
																			alt=''
																			style={{
																				width: '100%',
																				height: '100%',
																			}}
																		/>
																	</Button>
																</div>
															</div>
														</div>
														<div className='row mb-5'>
															<div className='col-12'>
																<h5>
																	{
																		'Bcc (Optional)'
																	}
																</h5>
																<div
																	className='form_blk'
																	style={{
																		flexDirection:
																			'row',
																		display:
																			'flex',
																	}}
																>
																	<TextInput
																		className='input input-placeholder'
																		placeholder='Press the button to load Contacts | Enter space to add multiple emails'
																		type='text'
																		name='bcc'
																		handleChange={(
																			e: any
																		) => {
																			setReferralObject(
																				{
																					...referralObject,
																					[e
																						.target
																						.name]:
																						e.target.value.replace(
																							/ /g,
																							','
																						),
																				}
																			);
																			setFieldValue(
																				'bcc',
																				e.target.value.replace(
																					/ /g,
																					','
																				)
																			);
																		}}
																		handleBlur={
																			handleBlur
																		}
																		value={
																			referralObject.bcc
																		}
																	/>
																	<Button
																		onClick={() => {
																			setIsCcEmailAdd(
																				false
																			);
																			handleContactModal();
																		}}
																		style={{
																			backgroundColor:
																				'white',
																		}}
																	>
																		<img
																			src={
																				phoneIcon
																			}
																			alt=''
																			style={{
																				width: '100%',
																				height: '100%',
																			}}
																		/>
																	</Button>
																</div>
															</div>
														</div>
														<div className='row'>
															<div className='col-12'>
																<h5 className='require'>
																	Referral
																	Information
																</h5>
																<div className='form_blk'>
																	<JoditEditor
																		ref={
																			editor
																		}
																		value={
																			referralObject.description
																		}
																		className='editor-class'
																		onBlur={(
																			newContent
																		) => {
																			setReferralObject(
																				{
																					...referralObject,
																					description:
																						newContent,
																				}
																			);
																			setFieldValue(
																				'message',
																				newContent
																			);
																		}} // preferred to use only this option to update the content for performance reasons
																	/>
																	{/* <TextArea
																		name="message"
																		type="text"
																		handleChange={
																			handleChange
																		}
																		className="input"
																		placeholder="Information"
																	/> */}

																	<ErrorText
																		text={
																			errors.message
																		}
																		isTouched={
																			touched.message
																		}
																	/>
																</div>
															</div>
														</div>
														<div className='btn_blk mt-5 pt-3'>
															<button
																type='submit'
																className='site_btn w-100'
															>
																Send
															</button>
														</div>
													</div>
												</form>
											)}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<LawyerReferralSent
				active={sentRef}
				setActive={handleSentRef}
				lawyer={lawyer}
				referral={referral}
				totalRecords={totalRecords}
			/>
			<ContactModal
				active={contactModal}
				setActive={handleContactModal}
				referralObject={referralObject}
				setReferralObject={setReferralObject}
				isCcEmailAdd={isCcEmailAdd}
			/>
		</>
	);
}
