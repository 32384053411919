import Grid from "@mui/material/Grid";
import Image from "material-ui-image";
import EventCard from "../../../components/EventCard";
import Layout from "../../../components/Layout";
import MediaHeader from "../../../components/MediaHeader";
import NewsCard from "../../../components/NewsCard";
import ScreenLoader from "../../../components/Shared/ScreenLoader";
import TopBlock from "../../../components/TopBlock";
import { IPodcasts, IWebinars } from "../../../interfaces/Common";
import { useGetRole } from "../../../redux/selectors/Common";
import { useGetMediaSearchQuery } from "../../../redux/services/Firm/MediaLibrary";
import search from "../../../assets/images/search.svg";
import { useEffect, useState } from "react";

const AllMediaSearch = () => {
	const role = useGetRole();
	const [text, setText] = useState<any | null>("");
	const { data, isLoading } = useGetMediaSearchQuery(text);

	const handleSearch = (value:any) => {
		setText(value)
	}

	let upComingWebinars: any = [];
	let onDemandWebinars: any = [];

	data?.webnars?.forEach((upcoming: IWebinars) => {
		upcoming.webnar_type === "upcoming"
			? upComingWebinars.push(upcoming)
			: onDemandWebinars.push(upcoming);
	});

	return (
		<>
			<Layout>
				<section id="webinar" className="sec_pad_btm">
					<div className="contain sm">
						<div className="top_sec">
						<TopBlock title="Media Library" url={
									role === "client"
										? "dashboard"
										: `resources`
								} />
						<div className="left_blk">
							<div className="search_blk">
								<div className="img_blk">
									<img src={search} alt="" />
								</div>
								<input
									type="search"
									placeholder="Search"
									value={text}
									onChange={(e) =>
										handleSearch(e.target.value)
									}
								/>
							</div>
						</div>
					</div>
						{isLoading && <ScreenLoader show={true} />}
						<div>
						<MediaHeader />
							{data?.podcasts && (
								<h3 className="mt-5 mb-5">Podcasts</h3>
							)}
							<div className="library">
								{data?.podcasts.map(
									(item: IPodcasts, index: number) => {
										const date = new Date(item?.created_at);
										return (
											<div
												className="col-lg-6 col-md-12"
												key={index}
											>
												<div style={{margin:'10px'}} className="event_card">
													<a
														href={item.link}
														className="img_blk"
													>
														<Image
															src={item.image}
															alt="Event_Image"
															aspectRatio={5/4}
														/>
													</a>
													<div>
													<a href={item.link} className="content">
															<h5>{item.name}</h5>
													</a>

														<a href={item.link} style={{color: '#114d96'}} >
															<h5>{date.toDateString()}</h5>
														</a>
													</div>
													
												</div>
											</div>
										);
									}
								)}
							</div>
						</div>
						<div>
							{data?.webnars && (
								<h3 className="mt-5 mb-5">
									Upcoming Webinars{" "}
								</h3>
							)}
							<Grid container spacing={2}>
								{upComingWebinars?.map(
									(webinar: IWebinars, index: number) => {
										const date = new Date(webinar?.created_at);
										return (
											<Grid key={index} item xs={6}>
												<div className="webinar_card mt-0">
													<a
														href={webinar.link}
														className="card-image"
													>
														<img
															src={
																webinar.image
															}
															alt="webinar_image"
															style={{
																height: "22rem",
															}}
														/>
													</a>
													<div className="card-detail">
														<div className="card-title">
															<a
																href={
																	webinar.link
																}
																className="card-title"
															>
																{webinar.name}
															</a>
														</div>
														<a
															href={webinar.link}
															className="site_btn"
														>
															Join Now
														</a>
														<a href={webinar.link} style={{color: '#114d96'}} >
															<h5>{date.toDateString()}</h5>
														</a>
													</div>
												</div>
											</Grid>
										);
									}
								)}
							</Grid>
						</div>

						{data?.webnars && (
							<h3 className="mt-5 mb-5">
								On Demands Webinars{" "}
							</h3>
						)}
						<div className="library">
							{onDemandWebinars?.map(
								(webinar: IWebinars, index: number) => {
									const date = new Date(webinar?.created_at);
									return (
										<div
											className='col-lg-6 col-md-12 mb-5'
											key={index}
										>
											<div
												style={{ margin: "10px" }}
												className='event_card'
											>
												<a
													href={webinar.link}
													className='img_blk'
												>
													<Image
														src={webinar.image}
														alt='Event_Image'
														aspectRatio={5 / 4}
													/>
												</a>
												<div className='card-detail'>
													<div className='card-title'>
														<a
															href={
																webinar.link
															}
															className='card-title'
														>
															{webinar.name}
														</a>
													</div>
													<p></p>
													<a href={webinar.link} style={{color: '#114d96'}} >
															<h5>{date.toDateString()}</h5>
													</a>
													<a
														href={webinar.link}
														className='site_btn'
													>
														Join Now
													</a>
													
												</div>
											</div>
										</div>
									);
								}
							)}
						</div>

						{role !== 'client' && data?.events  && (
							<h3 className="mt-5 mb-5">Events</h3>
						)}
						{ role !== 'client' && (<div className="library">

							{data?.events?.map(
								(item: any, index: number) => {
									return (
										<div
											className='col-lg-6 col-md-12 mb-5'
											key={index}
										>
											<div style={{ margin: "10px" }}>
												<EventCard
													item={item}
													key={index}
												/>
											</div>
										</div>
									);
								}
							)}
						</div>)}

						{data?.news && (
							<h3 className="mt-5 mb-5">News & Articles</h3>
						)}

						<div className="library">
						{data?.news?.map((item: any, index: number) => {
							return (
								<div
									className='col-lg-6 col-md-12 mb-5'
									key={index}
								>
									<div style={{ margin: "10px" }}>
										<NewsCard item={item} key={index} />
									</div>
								</div>
							);
						})}
					</div>
					</div>
				</section>
			</Layout>
		</>
	);
};

export default AllMediaSearch;
