import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

type Props = {
	setRowsPerPage: any;
	setPages: any;
	totalRecords?: number;
	rowsPerPageOptions?: Array<number>;
	currentPage?: number;
};

const ReactPagination: React.FC<Props> = ({
	setRowsPerPage,
	setPages,
	totalRecords,
	rowsPerPageOptions,
	currentPage
}) => {
	const [selectedPage, setSelectedPage] = React.useState(
		currentPage === undefined ? 1 : currentPage
	);
	
	const [rowsPerPageToShow, setRowsPerPageToShow] = React.useState(10);

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setSelectedPage(newPage);
		setPages(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPageToShow(parseInt(event.target.value, 10));
		setRowsPerPage(parseInt(event.target.value, 10));
		setSelectedPage(1);
	};

	return (
		<TablePagination
			sx={{
				".MuiBox-root": {
					color: "black",

					"& .MuiSvgIcon-root": {
						color: "black",
					},
				},
				".MuiTablePagination-displayedRows": {
					fontSize: "2rem !important",
				},
				".MuiTablePagination-selectLabel": {
					fontSize: "2rem !important",
				},
				"..MuiTablePagination-toolbar": {
					maxHeight: "1rem !important",
				},
				".MuiTablePagination-select": {
					lineHeight: "4rem",
				},
			}}
			SelectProps={{
				MenuProps: {
					sx: {
						".MuiPaper-root": {
							backgroundColor: "grey",
							color: "black",
						},
						".MuiTablePagination-menuItem.Mui-selected": {
							":hover": {
								backgroundColor: "grey",
							},
						},
					},
				},
			}}
			size="medium"
			component="div"
			count={totalRecords ? totalRecords : 0}
			page={selectedPage}
			onPageChange={handleChangePage}
			rowsPerPage={rowsPerPageToShow ? rowsPerPageToShow : 0}
			onRowsPerPageChange={handleChangeRowsPerPage}
			rowsPerPageOptions={
				rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 50, 100]
			}
		/>
	);
};

export default ReactPagination;
