import Avatar from "@mui/material/Avatar";
import { useEffect, useState } from "react";

const ContactCard = ({
	item,
	addEmail,
	referralObject,
	setReferralObject,
	isCcEmailAdd,
}: any) => {

	const [isMobile, setIsMobile] = useState(false);

	const onPressEmail = (email: string) => {
		if (isCcEmailAdd) {
			const value =
				(referralObject.cc && referralObject.cc.slice(-1) === ",") ||
				!referralObject.cc
					? referralObject.cc + `${email}`
					: referralObject.cc + `,${email}`;

			setReferralObject({ ...referralObject, cc: value });
		} else {
			const value =
				(referralObject.bcc && referralObject.bcc.slice(-1) === ",") ||
				!referralObject.bcc
					? referralObject.bcc + `${email}`
					: referralObject.bcc + `,${email}`;

			setReferralObject({ ...referralObject, bcc: value });
		}
		addEmail();
	};

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 991px)");

		const handleMediaQueryChange = (e: any) => {
			setIsMobile(e.matches);
		};

		// Initial check
		handleMediaQueryChange(mediaQuery);

		// Add listener for changes
		mediaQuery.addEventListener("change", handleMediaQueryChange);

		// Clean up listener when component unmounts
		return () => {
			mediaQuery.removeEventListener("change", handleMediaQueryChange);
		};
	}, []);
	return (
		<>
			<div
				className='contact_card'
				onClick={() => onPressEmail(item.email)}
			>
				<div className='profile_blk'>
					<div className='img_blk'>
						<Avatar
							alt='Remy Sharp'
							src={item?.profile_image || item.image_url}
							sx={{ width: 56, height: 56 }}
						/>
					</div>
				</div>
				<div className='phone_email' style={{ marginLeft: "1rem" }}>
					<div className='text_blk'>
						<h6 className='fs_5 fw_500'>
							Name:{" "}
							<span style={{ color: "#31317f" }}>
								{item.name}
							</span>
						</h6>
					</div>
					<div className='phone'>
						<h6 className='fs_5 fw_500'>
							Phone no:{" "}
							<span style={{ color: "#31317f" }}>
								{item.phone}
							</span>
						</h6>
					</div>
					<div className='email'>
						<h6 className='fs_5 fw_500'>
							Email:{" "}
							<span style={{ color: "#31317f" }}>
								{item.email}
							</span>
						</h6>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactCard;
