import { useState } from "react";
import modalTick from "../../assets/images/modalTick.svg";
import NoImage from "../../assets/images/noImage.png";
import { ArrowLeft } from "../../icons";
import LawyerList from "./LawyerList";
import Image from "material-ui-image";

export default function LawyerReferralSent({
	active,
	setActive,
	lawyer,
	referral,
	totalRecords
}: any) {
	// console.log(lawyer)
	const [lawyerList, setLawyerList] = useState(false);
	const handleLawyerList = () => {
		setLawyerList(!lawyerList);
	};
	return (
		<>
			<section
				id="filter_popup"
				className={active ? "popup active" : "popup"}
			>
				<div className="overlay" onClick={setActive}></div>
				<div className="table_dv">
					<div className="table_cell">
						<div className="contain">
							<div className="row justify-content-center">
								<div className="col-lg-6 col-md-8 col-sm-10">
									<div className="_inner">
										<button
											type="button"
											className="back_btn"
											onClick={setActive}
										>
											<ArrowLeft />
										</button>
										<h2 className="text-center mb-5">
											Referral sent Successfully
										</h2>
										<div className="sent_ref_body">
											<div className="img_blk">
												<Image src={modalTick} alt="" />
											</div>
											<div className="user_profile_blk">
												<Image
													src={
														lawyer?.profile_image
															? lawyer.profile_image
															: NoImage
													}
												/>
											</div>
											<p className="fw_600 mb-1">
												{lawyer?.name}
											</p>
											<p className="fw_400 mb-0">
												{lawyer?.member_type}
											</p>
											<button
												className="site_btn mt-5 px"
												onClick={handleLawyerList}
											>
												Send referral to another lawyer
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<LawyerList
				active={lawyerList}
				setActive={handleLawyerList}
				referral={referral}
				totalRecords={totalRecords}
			/>
		</>
	);
}